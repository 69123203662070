:root {
  --color-black: #111;
  --color-blue-500: #00aff6;
  --color-blue-700: #17273c;
  --color-brown-500: #654b26;
  --color-gray-300: #f5efe9;
  --color-gray-500: #d5d0c7;
  --color-gray-700: #8a8678;
  --color-green-300: #42ffc6;
  --color-green-400: #42ffc6;
  --color-green-500: #00f086;
  --color-green-600: #00674a;
  --color-green-700: #00453b;
  --color-green-900: #0d443b;
  --color-red-200: #ffcbf0;
  --color-red-300: #ffb3ee;
  --color-red-500: #ff0023;
  --color-red-700: #9b0036;
  --color-yellow-300: #ffe966;
  --color-yellow-400: #ffe966;
  --color-yellow-500: #ffc800;
  --color-white: #fff;

  /* Legacy */
  --color-gray-10: #f8f8f8;
  --color-gray-20: #efefef;
  --color-gray-50: #eee;
  --color-gray-70: #4d4d4d;
  --color-gray-80: #333;
  --color-gray-90: #1c1c1c;
  --color-black: #111;
  --color-white: #fff;
  --color-purple: #5e0cff;
  --color-cyan: #00f4ff;
}
