:root {
  --font-family-base: 'Poppins', sans-serif;
  --font-family-alt: 'Anton', sans-serif;
  --font-weight-400: 400;
  --font-weight-600: 600;
  --font-weight-800: 800;

  --line-height-extra-small: 0.95;
  --line-height-small: 1;
  --line-height-normal: 1.5;

  --font-size-10: 1rem;
  --font-size-12: 1.2rem;
  --font-size-14: 1.4rem;
  --font-size-16: 1.6rem;
  --font-size-18: 1.8rem;
  --font-size-20: 2rem;
  --font-size-24: 2.4rem;
  --font-size-28: 2.8rem;
  --font-size-32: 3.2rem;
  --font-size-58: 5.8rem;
  --font-size-70: 7rem;
}
