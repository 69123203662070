:root {
  --breakpointSm: 30em;
  --breakpointMd: 48em;
  --breakpointLg: 64em;
  --breakpointXl: 80em;
}

:export {
  viewportSm: screen and (min-width: 30em); /* 480px */
  viewportMd: screen and (min-width: 48em); /* 768px */
  viewportLg: screen and (min-width: 64em); /* 1024px */
  viewportXl: screen and (min-width: 80em); /* 1280px */
  viewportXxl: screen and (min-width: 121em); /* 1920px +1em for final bound */
  breakpointSm: 480px;
  breakpointMd: 768px;
  breakpointLg: 1024px;
  breakpointXl: 1440px;
}

@custom-media --viewport-sm screen and (min-width: 480px);
@custom-media --viewport-md screen and (min-width: 768px);
@custom-media --viewport-lg screen and (min-width: 1024px);
@custom-media --viewport-xl screen and (min-width: 1440px);
